import { graphql } from "react-relay";

export const UpdateUserMutation = graphql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        key
        name
        email
        assignedRoles
        allRoles
      }
    }
  }
`;
