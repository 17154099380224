import { useMemo, useState, useCallback } from "react";

import Box from "@mui/material/Box";
import { Paper } from "@stacklet/ui";
import { graphql, usePaginationFragment } from "react-relay";

import NoData from "app/components/empty-state/NoData";
import { HumanTimestamp } from "app/components/HumanTimestamp";
import { TableLink } from "app/components/links";
import Table from "app/components/table/XGridTable";
import ToolbarHeader from "app/components/ToolbarHeader";
import { useLoadNext } from "app/hooks";
import { createSingleFilter } from "app/utils/filters";

import {
  type UserList_users$data,
  type UserList_users$key,
} from "./__generated__/UserList_users.graphql";
import Create from "./components/actions/Create";
import RemoveSelectedUsers from "./components/actions/RemoveSelectedUsers";

import type { UserListRefetchQuery } from "./__generated__/UserListRefetchQuery.graphql";
import type { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-pro";

interface Props {
  isSsoUsers: boolean;
  queryRef: UserList_users$key;
  hasToolbar?: boolean;
  hasRoles?: boolean;
}

export type User = UserList_users$data["users"]["edges"][0]["node"];

export function UserList({
  isSsoUsers,
  queryRef,
  hasRoles = true,
  hasToolbar = true,
}: Props) {
  const [selectedRows, setSelectedRows] = useState<User[]>([]);

  const filterElement = createSingleFilter("sso", isSsoUsers.toString());

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    UserListRefetchQuery,
    UserList_users$key
  >(
    graphql`
      fragment UserList_users on Query
      @relay(mask: false)
      @argumentDefinitions(
        first: { type: "Int" }
        last: { type: "Int" }
        after: { type: "String" }
        before: { type: "String" }
        filterElement: { type: "FilterElementInput" }
      )
      @refetchable(queryName: "UserListRefetchQuery") {
        users(
          first: $first
          last: $last
          after: $after
          before: $before
          filterElement: $filterElement
        ) @connection(key: "UserList_users") {
          edges {
            node {
              id
              allRoles
              name
              lastLogin
              key
              groups
            }
          }
          pageInfo {
            total
          }
        }
      }
    `,
    queryRef,
  );

  const loadMore = useLoadNext(hasNext, loadNext);

  const sortComparator = (v1: string[], v2: string[]) =>
    v1[0].localeCompare(v2[0]);

  const rows = (data?.users.edges ?? [])
    .filter((edge) => edge.node)
    .map((edge) => {
      const { node } = edge;
      return {
        ...node,
        uuid: node.id,
        name: node.name,
        lastLogin: node.lastLogin,
        roles: node.allRoles,
        groups: node.groups,
        key: node.key,
      };
    });

  type UserRow = (typeof rows)[0];
  const columns: GridColDef<UserRow>[] = useMemo(
    () => [
      {
        headerName: "User",
        field: "name",
        flex: 1,
        renderCell: (params) => {
          const { key, name } = params.row;
          if (!isSsoUsers && name) {
            return <TableLink name={name} to={`/settings/users/${key}`} />;
          }
          return <span>{name || key}</span>;
        },
      },
      {
        headerName: "Roles",
        field: "allRoles",
        flex: 0.5,
        renderCell: (params) => {
          const { allRoles } = params.row;
          return allRoles?.length > 0 ? (
            <span>{allRoles.join(", ")}</span>
          ) : (
            "--"
          );
        },
        sortable: true,
        sortComparator,
      },
      {
        headerName: "Last Login",
        field: "lastLogin",
        flex: 0.5,
        renderCell: (params) => {
          const { lastLogin } = params.row;
          if (lastLogin) {
            return <HumanTimestamp timestamp={params.row.lastLogin} />;
          }
        },
      },
      {
        headerName: "SSO Groups",
        field: "groups",
        flex: 1,
        renderCell: (params) => {
          const { groups } = params.row;
          return groups.length > 0 ? <span>{groups.join(", ")}</span> : "--";
        },
        sortable: true,
        sortComparator,
      },
    ],
    [isSsoUsers],
  );

  const updateSelectedModel = useCallback(
    (newSelectionModel: GridRowSelectionModel) => {
      const selectedRows = rows.filter((row) =>
        newSelectionModel.includes(row.id),
      );
      setSelectedRows(selectedRows);
    },
    [rows],
  );

  const rowCount = data.users.pageInfo.total || rows.length;

  const columnVisibilityModel = {
    groups: isSsoUsers,
    allRoles: hasRoles,
  };

  return (
    <Paper>
      {!isSsoUsers ? (
        <ToolbarHeader hideSearchBar={true}>
          <Box component="span" mr={2}>
            <RemoveSelectedUsers selectedRows={selectedRows} />
          </Box>
          <Create filterElement={filterElement} />
        </ToolbarHeader>
      ) : null}

      {rowCount === 0 ? (
        <NoData message="No users exist yet." title="No Users" />
      ) : (
        <Box height={"80%"}>
          <Table
            checkboxSelection={!isSsoUsers}
            columnVisibilityModel={columnVisibilityModel}
            columns={columns}
            getRowId={(row) => row.uuid}
            hasToolbar={hasToolbar}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  groups: isSsoUsers,
                  allRoles: hasRoles,
                },
              },
            }}
            loading={isLoadingNext}
            onRowSelectionModelChange={updateSelectedModel}
            onRowsScrollEnd={loadMore}
            rowCount={rowCount}
            rowSelectionModel={selectedRows.map((row) => row.id)}
            rows={rows}
            tableId="admin-users-user-list"
          />
        </Box>
      )}
    </Paper>
  );
}
