import type React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Tabs, ToggleTabsList, ToggleTab } from "@stacklet/ui";

import { SearchBar } from "app/components/SearchBar";
import PlusIcon from "assets/icons/plus.svg?react";

interface Props {
  /** Active tab value */
  activeTab: string;
  /** Tab change handler */
  onTabChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export function AccessControlActionBar({ activeTab, onTabChange }: Props) {
  const handleTabChange = (
    event: React.SyntheticEvent | null,
    value: string | number | null,
  ) => {
    if (value === null) return;
    const newValue = typeof value === "number" ? value.toString() : value;
    onTabChange(event || ({} as React.SyntheticEvent), newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <SearchBar
          formSx={{ width: "25rem" }}
          onSearch={undefined}
          title="Search"
        />
        <Button
          buttonType="main"
          startIcon={<PlusIcon height={20} width={20} />}
          isDisabled
        >
          Add SSO Group
        </Button>
      </Box>

      <Box
        sx={{
          mb: 2,
          border: 1,
          borderColor: "divider",
          borderRadius: 1,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Typography variant="body2">View</Typography>
          <Tabs onChange={handleTabChange} value={activeTab}>
            <ToggleTabsList secondary>
              <ToggleTab value="users">Users</ToggleTab>
              <ToggleTab value="sso-groups">SSO Groups</ToggleTab>
            </ToggleTabsList>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
}
