import { useState, useEffect } from "react";

import * as Sentry from "@sentry/browser";
import { Button, Tooltip } from "@stacklet/ui";
import { useMutation } from "react-relay";

import { DeleteConfirmationDialog } from "app/components/dialogs";
import { useAlertContext } from "app/contexts";
import { RemoveUserMutation } from "app/mutations";

import type { User } from "../../UserList";
import type { RemoveUserMutation as RemoveUserMutationType } from "app/mutations/__generated__/RemoveUserMutation.graphql";

type SelectedUsers = User[];

interface Props {
  selectedRows: SelectedUsers;
}

export default function RemoveUsers({ selectedRows }: Props): JSX.Element {
  const { alertDispatch } = useAlertContext();

  const [isDeleteConfirmDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState<boolean>(false);
  const [deleteRows, setDeleteRows] = useState<SelectedUsers>([]);

  useEffect(() => {
    setDeleteRows([...selectedRows]);
    return () => {
      setDeleteRows([]);
    };
  }, [selectedRows]);

  const onDeleteFailure = (errorMessage: string) => {
    alertDispatch({
      message: `failed to remove users: ${errorMessage}`,
      severity: "error",
    });
  };

  //TODO: [PLATFORM-2403] refactor from using removeUser to removeUsers
  const [remove] = useMutation<RemoveUserMutationType>(RemoveUserMutation);

  const deleteUsers = (selectedUsers: SelectedUsers) => {
    //TODO: [PLATFORM-2403] refactor from using removeUser to removeUsers
    selectedUsers.forEach((user) => {
      remove({
        variables: {
          key: user.key,
        },
        onCompleted(response, errors) {
          if (errors) {
            errors.forEach((error) => {
              onDeleteFailure(error.message);
            });
          } else {
            alertDispatch({
              message: "successfully removed selected user(s)",
              severity: "success",
            });
          }
          setIsConfirmDeleteDialogOpen(false);
        },
        onError: (error) => {
          Sentry.captureException(error);
        },
      });
    });
  };

  const openDialog = (value: boolean) => {
    setIsConfirmDeleteDialogOpen(value);
  };

  const confirmDelete = () => {
    deleteUsers(deleteRows);
  };

  return (
    <>
      <Tooltip
        title={
          deleteRows.length === 0
            ? "Must select at least one user to remove"
            : ""
        }
      >
        <span>
          <Button
            buttonType="destructive"
            dataTestID="remove-selected-users"
            isDisabled={deleteRows.length === 0}
            onClick={() => {
              setIsConfirmDeleteDialogOpen(true);
            }}
          >
            Remove
          </Button>
        </span>
      </Tooltip>
      <DeleteConfirmationDialog
        action="Remove"
        confirmDelete={confirmDelete}
        isDialogOpen={isDeleteConfirmDialogOpen}
        openDialog={openDialog}
        title="User(s)"
      />
    </>
  );
}
