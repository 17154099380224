import { DEFAULT_PAGE_SIZE } from "app/utils/consts";

export function useLoadNext(
  hasNext: boolean,
  loadNext: (pageSize: number) => void,
  pageSize?: number,
): () => void {
  const loadMore = (): void => {
    if (hasNext) {
      loadNext(pageSize || DEFAULT_PAGE_SIZE);
    }
  };

  return loadMore;
}
