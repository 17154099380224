import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/browser";
import { Button, COLORS } from "@stacklet/ui";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";
import { z } from "zod";

import { useFormErrors } from "app/hooks";
import { AddUserMutation, UpdateUserMutation } from "app/mutations";
import {
  NON_SSO_ROLE,
  EDIT_FORM_SUBMIT_BUTTON_NAME,
  EDIT_FORM_LOADING_SUBMIT_BUTTON_NAME,
} from "app/utils/consts";

import type { FilterElementInput } from "../__generated__/UserListRefetchQuery.graphql";
import type {
  AddUserInput,
  AddUserMutation as AddUserMutationType,
} from "app/mutations/__generated__/AddUserMutation.graphql";
import type {
  UpdateUserInput,
  UpdateUserMutation as UpdateUserMutationType,
} from "app/mutations/__generated__/UpdateUserMutation.graphql";

const StyledForm = styled("form")(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: "500px",
  color: COLORS.navy.L30,
}));

const schema = z.object({
  name: z.string().min(1),
  email: z.string().email().min(1),
});

interface Props {
  onAdded: (message: string) => void;
  onCancel: () => void;
  filterElement?: FilterElementInput;
  selectedEditUserData?: UpdateUserInput;
}

const defaultUserData = {
  name: "",
  email: "",
  key: 0,
};

export default function UpsertUserForm({
  selectedEditUserData = defaultUserData,
  onAdded,
  onCancel,
  filterElement,
}: Props) {
  const [formErrors, setFormErrors] = useFormErrors();
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateUserInput & AddUserInput>({
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const addUserAction = (data: AddUserInput) => {
    setIsLoadingButton(true);
    const connectionId = ConnectionHandler.getConnectionID(
      "client:root",
      "UserList_users",
      { filterElement: filterElement ?? null },
    );

    const { name, email } = data;
    const formattedUsername = email.replace("@", "_at_");

    addUser({
      variables: {
        input: {
          name,
          username: formattedUsername,
          email,
          roles: [NON_SSO_ROLE],
        },
        connections: [connectionId],
      },
      onCompleted(response, errors) {
        if (errors) {
          setFormErrors(errors.map((error) => error.message));
        }
        if (response.addUser?.user) {
          onAdded(`Added user ${response.addUser.user.name} successfully`);
        }
        setIsLoadingButton(false);
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsLoadingButton(false);
      },
    });
  };

  const [addUser] = useMutation<AddUserMutationType>(AddUserMutation);

  const updateUserAction = (data: UpdateUserInput) => {
    setIsLoadingButton(true);
    const { name, email } = data;

    updateUser({
      variables: {
        input: {
          key: selectedEditUserData.key,
          name,
          email,
        },
      },
      onCompleted(response, errors) {
        if (errors) {
          setFormErrors(errors.map((error) => error.message));
        }
        if (response.updateUser?.user) {
          onAdded(`Edited user ${response.updateUser.user.name} successfully`);
        }
        setIsLoadingButton(false);
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsLoadingButton(false);
      },
    });
  };

  const [updateUser] = useMutation<UpdateUserMutationType>(UpdateUserMutation);

  return (
    <Grid>
      <StyledForm
        onSubmit={
          selectedEditUserData.name
            ? handleSubmit(updateUserAction)
            : handleSubmit(addUserAction)
        }
      >
        {!selectedEditUserData.name ? (
          <Typography mb={2} variant="body1">
            The new admin will receive an email with a temporary password to
            activate their account.
          </Typography>
        ) : null}
        <FormHelperText id="outlined-user-name">Name *</FormHelperText>
        <FormControl variant="outlined" fullWidth>
          <Controller
            control={control}
            defaultValue={selectedEditUserData.name ?? ""}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={fieldState.invalid}
                inputProps={{ "data-testid": "user-name-input" }}
                placeholder="Enter the name for this admin"
                size="small"
                variant="outlined"
                required
              />
            )}
          />
        </FormControl>

        <FormHelperText id="outlined-user-name" sx={{ mt: 3 }}>
          Email address *
        </FormHelperText>
        <FormControl variant="outlined" fullWidth>
          <Controller
            control={control}
            defaultValue={selectedEditUserData.email ?? ""}
            name="email"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={fieldState.invalid}
                inputProps={{ "data-testid": "user-email-input" }}
                placeholder="Enter the email address for this admin"
                size="small"
                variant="outlined"
                required
              />
            )}
          />
        </FormControl>

        {formErrors
          ? formErrors.map((error) => (
              <Box key={error.id} mt={3}>
                <Alert severity="error">{error.message}</Alert>
              </Box>
            ))
          : null}
        <Box display="flex" justifyContent="space-between" mb={2} mt={3}>
          <Button
            buttonType="outline-main"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            buttonType="main"
            data-testid="create-user-button"
            isDisabled={!isValid || isLoadingButton}
            loading={{
              isLoading: isLoadingButton,
              loadingMessage: selectedEditUserData.name
                ? EDIT_FORM_LOADING_SUBMIT_BUTTON_NAME
                : "Creating and sending invite",
            }}
            onClick={
              selectedEditUserData.name
                ? handleSubmit(updateUserAction)
                : handleSubmit(addUserAction)
            }
          >
            {selectedEditUserData.name
              ? EDIT_FORM_SUBMIT_BUTTON_NAME
              : "Create and send invite"}
          </Button>
        </Box>
      </StyledForm>
    </Grid>
  );
}
