import { graphql } from "react-relay";

export const RemoveUserMutation = graphql`
  mutation RemoveUserMutation($key: Int!) {
    removeUser(key: $key) {
      removed {
        id @deleteRecord
      }
    }
  }
`;
