import { Suspense } from "react";

import Box from "@mui/material/Box";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useSearchParams } from "react-router";

import {
  type FilterElementInput,
  type AccessControlSettingsRootQuery,
} from "./__generated__/AccessControlSettingsRootQuery.graphql";
import { AccessControlActionBar } from "./AccessControlActionBar";
import { AccessControlSettingsPage } from "./AccessControlSettingsPage";
import { UserList } from "../users/user-list/UserList";

export function AccessControlSettingsRoot() {
  const [searchParams, setSearchParams] = useSearchParams({});
  const view = searchParams.get("view");
  const search = searchParams.get("search");
  const tabValue = view || "sso-groups";

  const ssoFilter = {
    single: {
      name: "sso",
      operator: "equals",
      value: "true",
    },
  };

  let userFilterElement: FilterElementInput;
  if (search) {
    userFilterElement = {
      multiple: {
        operator: "AND",
        operands: [
          ssoFilter,
          {
            single: {
              name: "username",
              operator: "contains",
              value: search,
            },
          },
        ],
      },
    };
  } else {
    userFilterElement = ssoFilter;
  }

  const data = useLazyLoadQuery<AccessControlSettingsRootQuery>(
    graphql`
      query AccessControlSettingsRootQuery(
        $userFilterElement: FilterElementInput
      ) {
        ...AccessControlSettingsPage_query
        ...UserList_users @arguments(filterElement: $userFilterElement)
      }
    `,
    {
      userFilterElement,
    },
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchParams({ view: newValue });
  };

  return (
    <AccessControlSettingsPage queryRef={data}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <AccessControlActionBar
          activeTab={tabValue}
          onTabChange={handleTabChange}
        />

        <Suspense fallback={null}>
          {tabValue === "sso-groups" ? (
            <Box className="mt-4">Groups</Box>
          ) : tabValue === "users" ? (
            <Box className="mt-4">
              <UserList
                hasRoles={false}
                hasToolbar={false}
                isSsoUsers={true}
                queryRef={data}
              />
            </Box>
          ) : null}
        </Suspense>
      </Box>
    </AccessControlSettingsPage>
  );
}
