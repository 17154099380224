import { useState } from "react";

import Button from "@mui/material/Button";

import { SuspenseDialog } from "app/components/dialogs";
import { useAlertContext } from "app/contexts/AlertContext";

import UpsertUserForm from "../UpsertUserForm";

import type { FilterElementInput } from "../../__generated__/UserListRefetchQuery.graphql";

interface Props {
  filterElement?: FilterElementInput;
}

export default function Create({ filterElement }: Props) {
  const { alertDispatch } = useAlertContext();

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const onAdded = (message: string) => {
    alertDispatch({
      message,
      severity: "success",
    });
    setAddDialogOpen(false);
  };

  const showDialog = () => {
    setAddDialogOpen(true);
  };

  return (
    <>
      <Button onClick={showDialog} variant="contained">
        Create new non SSO admin
      </Button>

      <SuspenseDialog
        onClose={() => {
          setAddDialogOpen(false);
        }}
        open={addDialogOpen}
        title="Create new admin"
      >
        <UpsertUserForm
          filterElement={filterElement}
          onAdded={(message) => onAdded(message)}
          onCancel={() => {
            setAddDialogOpen(false);
          }}
        />
      </SuspenseDialog>
    </>
  );
}
