import type {
  FilterBooleanOperator,
  FilterElementInput,
  FilterValueInput,
  FilterBooleanOperationInput,
} from "app/views/cloudops/resource-matches/__generated__/ResourceMatchesTableRefetchQuery.graphql";

// Re-export so we don't have to arbitrarily import these types from generated code
export type {
  FilterBooleanOperator,
  FilterElementInput,
  FilterValueInput,
  FilterBooleanOperationInput,
};

/**
 * Creates a single filter element
 *
 * @param name - The name of the filter field
 * @param value - The value to filter by
 * @param operator - Optional operator (e.g., 'equals', 'contains')
 * @returns A FilterElementInput with a single filter
 */
export function createSingleFilter(
  name: string,
  value: string,
  operator?: string,
): FilterElementInput {
  return {
    single: {
      name,
      value,
      ...(operator ? { operator } : {}),
    },
  };
}

/**
 * Creates a multiple filter element with the given operator and operands
 *
 * @param operands - Array of filter elements
 * @param operator - The boolean operator to combine filters with ('AND' or 'OR')
 * @returns A FilterElementInput with multiple filters
 */
export function createMultipleFilter(
  operands: FilterElementInput[],
  operator: FilterBooleanOperator = "AND",
): FilterElementInput {
  if (operands.length === 1) {
    return operands[0];
  }

  return {
    multiple: {
      operator,
      operands,
    },
  };
}

/**
 * Creates a filter element from an array of filters
 * Automatically decides whether to use single or multiple based on the number of filters
 *
 * @param filters - Array of filters to combine
 * @param operator - The boolean operator to combine filters with ('AND' or 'OR')
 * @returns A FilterElementInput
 */
export function createFilterElement(
  filters: { name: string; value: string; operator?: string }[],
  operator: FilterBooleanOperator = "AND",
): FilterElementInput | null {
  if (filters.length === 0) {
    return null;
  }

  if (filters.length === 1) {
    return createSingleFilter(
      filters[0].name,
      filters[0].value,
      filters[0].operator,
    );
  }

  const operands = filters.map((filter) =>
    createSingleFilter(filter.name, filter.value, filter.operator),
  );

  return createMultipleFilter(operands, operator);
}
